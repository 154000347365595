<template>
  <div class="thirdColumnList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>三级栏目管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>名称</span>
      <el-input
        placeholder="请输入栏目名称"
        v-model.trim="list.columnName"
      ></el-input>
      <el-button type="primary" @click="onSearch">搜索</el-button>
      <el-button
        type="primary"
        @click="addSecondDialogVisible = true"
        v-if="
          $store.state.powerList.indexOf('system:permission:column:insert') !==
          -1
        "
        >添加</el-button
      ><el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-table
      @selection-change="handleSelectionChange"
      :data="tableData"
      style="width: 100%"
      border
    >
      <el-table-column
        align="center"
        type="selection"
        width="55"
      ></el-table-column>
      <el-table-column align="center" prop="columnId" label="ID" width="180">
      </el-table-column>
      <el-table-column
        align="center"
        prop="columnSort"
        label="排序"
        width="180"
      >
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.columnSort"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            @click="onChangeSort(row)"
            class="button-new-tag"
            size="small"
            >{{ row.columnSort }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="columnRouteUrl"
        label="栏目路由"
        width="180"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="columnName"
        label="栏目名称"
        width="180"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="columnCreateTime"
        label="栏目创建时间"
      >
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            @click="onEditColunm(row)"
            v-if="
              $store.state.powerList.indexOf(
                'system:permission:column:delete'
              ) !== -1
            "
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onDel(row)"
            v-if="
              $store.state.powerList.indexOf(
                'system:permission:column:update'
              ) !== -1
            "
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-button
      class="btn"
      type="primary"
      @click="onAllDelColumn"
      v-if="
        $store.state.powerList.indexOf('system:permission:column:delete') !== -1
      "
      >批量删除</el-button
    >
    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="this.list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination> -->
    <el-dialog
      title="编辑三级栏目"
      :visible.sync="editDialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="栏目主键">
          <el-input
            placeholder="请输入要添加的栏目名称"
            v-model="editColumnList.columnId"
            readonly=""
          ></el-input>
        </el-form-item>
        <el-form-item label="栏目路由">
          <el-input v-model="editColumnList.columnRouteUrl"></el-input>
        </el-form-item>
        <el-form-item label="栏目名称">
          <el-input v-model="editColumnList.columnName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditColumnOk">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="添加三级栏目"
      :visible.sync="addSecondDialogVisible"
      width="30%"
    >
      <el-form ref="form">
        <el-form-item label="栏目名称">
          <el-input
            placeholder="请输入栏目名称"
            v-model="addSecondColumn.columnName"
          ></el-input>
          <el-form-item label="栏目路由">
            <el-input
              v-model="addSecondColumn.columnRouteUrl"
              placeholder="请输入栏目路由"
            ></el-input>
          </el-form-item>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addSecondDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddSecondColumnOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  columnList,
  addColumn,
  editSort,
  editColumn,
  delColum,
} from "../../api/column";
export default {
  name: "thirdColumnList",
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      tableData: [],
      addSecondDialogVisible: false,
      editDialogVisible: false,
      value: [],
      list: {
        roleId: "",
        columnTier: "",
        parentId: "",
        columnName: "",
        currentPage: 1,
        pageSize: 99999,
      },
      addSecondColumn: {
        columnName: "",
        columnRouteUrl: "",
      },
      editColumnList: {},
    };
  },
  created() {
    this.getColumnList();
  },
  methods: {
    async getColumnList() {
      const { data } = await columnList(this.list);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      const list = data.list.filter((item) => {
        return item.columnTier === 3 && item.columnParentId === Number(this.id);
      });
      console.log(list, "listt");
      this.tableData = list;
    },
    async onAddSecondColumnOk() {
      if (
        this.addSecondColumn.columnName === "" ||
        this.addSecondColumn.columnRouteUrl === ""
      ) {
        this.$message({
          showClose: true,
          message: "请把数据填写完整!",
          type: "warning",
        });
        return;
      }
      const { data } = await addColumn({
        columnName: this.addSecondColumn.columnName,
        columnRouteUrl: this.addSecondColumn.columnRouteUrl,
        columnTier: 3,
        columnParentId: Number(this.id),
      });
      this.addSecondDialogVisible = false;
      this.getColumnList();
    },
    async onDel(row) {
      this.$confirm("是否删除这条栏目?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delColum({
            columnIdListStr: [row.columnId],
          });

          if (data.code === 0) {
            this.getColumnList();

            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            this.$message({
              type: "error",
              message: "删除失败",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSelectionChange(val) {
      var arr = [];
      val.map((item) => {
        arr.push(item.columnId);
      });
      this.value = arr;
    },
    onAllDelColumn() {
      console.log(this.value);
      this.$confirm("是否批量删除这条栏目?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delColum({
            columnIdListStr: this.value,
          });
          if (data.code === 0) {
            this.getColumnList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            this.$message({
              type: "error",
              message: "删除失败",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    async handleInputConfirm(row) {
      const { data } = await editSort({
        columnId: row.columnId,
        columnSort: row.columnSort,
      });
      row.inputVisible = false;
      this.getColumnList();
      console.log(data, "data");
    },
    onSearch() {
      this.getColumnList();
      this.list.columnName = "";
    },
    onEditColunm(row) {
      this.editColumnList = row;
      this.editDialogVisible = true;
    },
    async onEditColumnOk() {
      if (
        this.editColumnList.columnName === "" ||
        this.editColumnList.columnRouteUrl === ""
      ) {
        this.$message({
          showClose: true,
          message: "请把数据填写完整!",
          type: "warning",
        });
        return;
      }
      const { data } = await editColumn(this.editColumnList);
      console.log(data, "123123123");
      if (data.code === 0) {
        this.$message({
          showClose: true,
          message: "编辑成功",
          type: "success",
        });
      } else {
        this.$message({
          showClose: true,
          message: "编辑失败请稍后重试",
          type: "warning",
        });
      }
      this.editDialogVisible = false;
    },
    async handleInputConfirm(row) {
      const { data } = await editSort({
        columnId: row.columnId,
        columnSort: row.columnSort,
      });
      row.inputVisible = false;
      this.getColumnList();
      console.log(data, "data");
    },
    handleSizeChange(sixe) {
      this.list.pageSize = sixe;
      this.getColumnList();
    },
    handleCurrentChange(Current) {
      this.list.currentPage = Current;
      this.getColumnList();
    },
  },
};
</script>
<style lang="less" scoped>
.thirdColumnList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 500px;
      margin: 0 30px;
    }
  }
  /deep/.el-table {
    margin-top: 50px;
  }
  .btn {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>